import { useCallback, useEffect, useState } from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import {
  Hydrate,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { SpeedInsights } from '@vercel/speed-insights/next';
import { useRouter } from 'next/router';
import theme from 'styles/theme';
import { LoggingProvider } from 'utils/context/loggingContext';
import { MessengerProvider } from 'utils/context/messengerContext';
import { OpsProvider } from 'utils/context/opsContext';
import { useAnalytics } from 'utils/hooks/useAnalytics';
import { useViewStore } from 'utils/stores/ViewStore';
import { GlobalHead } from 'components/layout/GlobalHead';
import '../public/styles/globals.css';
import '../public/styles/typography.css';
import '../components/common/TextEditor/style.css';

const queryClient = new QueryClient();

function OpsApp({ Component, pageProps }) {
  const [hasLoaded, setHasLoaded] = useState(false);
  const router = useRouter();
  const { pathname } = router;
  const { updateActivePageFromUrl } = useAnalytics();
  const { closeModal } = useViewStore();

  const handleRouteChange = useCallback(
    url => {
      updateActivePageFromUrl(url);
      closeModal();
    },
    [updateActivePageFromUrl, closeModal],
  );

  useEffect(() => {
    router.events.on('routeChangeComplete', handleRouteChange);
    router.events.on('hashChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
      router.events.off('hashChangeComplete', handleRouteChange);
    };
  }, [router.events, handleRouteChange]);

  useEffect(() => {
    if (hasLoaded) return;
    updateActivePageFromUrl(pathname);
    setHasLoaded(true);
  }, [hasLoaded, pathname, updateActivePageFromUrl]);

  return (
    <>
      <GlobalHead />
      <QueryClientProvider client={queryClient}>
        <Hydrate state={pageProps.dehydratedState}>
          <ChakraProvider theme={theme} resetCSS={true}>
            <LoggingProvider>
              <MessengerProvider>
                <OpsProvider>
                  <Component {...pageProps} />
                </OpsProvider>
              </MessengerProvider>
            </LoggingProvider>
          </ChakraProvider>
        </Hydrate>
        <ReactQueryDevtools
          initialIsOpen={false}
          toggleButtonProps={{
            className: 'devToolsButton',
          }}
        />
      </QueryClientProvider>
      {process.env.NODE_ENV == 'production' && <SpeedInsights />}
    </>
  );
}

export default OpsApp;
