import {
  applyContext,
  fetchObject,
  mutateObject,
  Mutations,
  Queries,
} from 'helpers';
import { GQL, paging, Prettify, ReflexResponse } from 'types';

export type fetchJobsProps = {
  uuid?: string;
  objectType?: JobNamespaceType;
};

export enum JobNamespaceType {
  SHIFT = 'SHIFT',
  USER = 'USER',
}

export const fetchJobs = async (
  data: fetchJobsProps,
  contextOverride?: string,
  returnKey: string = 'jobs',
): Promise<ReflexResponse> =>
  await fetchObject({
    route: applyContext(GQL, contextOverride || 'JOBS_QUERY'),
    query: Queries.JOBS_QUERY,
    data,
    returnKey,
  });
