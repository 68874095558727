import { apiWorkerShiftEvent, review, tag, workerShiftEvent } from '..';
import { uuid } from './common';
import { communicationBatch } from './communicationBatch';
import { location, LocationType } from './location';
import { noteType } from './note';
import { payment } from './payment';
import { activePlaybook, playbook } from './playbook';
import { apiRetailer } from './retailer';
import { role } from './role';
import { shiftAuditLog } from './shiftAuditLog';
import { shiftCriteria } from './shiftCriteria';
import { apiUserLight, normalizedUserLight, user } from './user';
import { benefitAccrualEvent } from './workerBenefit';
import { workershift } from './workershift';

export const DEFAULT_CONTACT_INTEGRATION = ''; // 2d66e00b43554094a2777b5664e8e743
export const DEFAULT_INFO_INTEGRATION = ''; // 2d66e00b43554094a2777b5664e8e743
export const DEFAULT_HOURLY_RATE = 16;

export enum ShiftStatus {
  MISSED = 'MISSED',
  FILLED = 'FILLED',
  FILLED_PARTIAL = 'FILLED_PARTIAL',
  OVERBOOKED = 'OVERBOOKED',
  OPEN = 'OPEN',
  CANCELLED = 'CANCELLED',
  COMPLETE = 'COMPLETE',
}

export enum ShiftCancelClassification {
  RETAILER_CANCEL = 'RETAILER_CANCEL',
  STAFF_CANCEL = 'STAFF_CANCEL',
  UNFILLED = 'UNFILLED',
  OTHER = 'OTHER',
  NONE_SPECIFIED = 'NONE_SPECIFIED',
}

export const ShiftCancelClassificationLabel = {
  [ShiftCancelClassification.RETAILER_CANCEL]: [
    ShiftCancelClassification.RETAILER_CANCEL,
    'Retailer Cancel: Cancel fee',
  ],
  [ShiftCancelClassification.STAFF_CANCEL]: [
    ShiftCancelClassification.STAFF_CANCEL,
    'Reflex Cancel: No cancel fee',
  ],
  [ShiftCancelClassification.UNFILLED]: [
    ShiftCancelClassification.UNFILLED,
    'Reflex - Unfilled: No cancel fee',
  ],
  [ShiftCancelClassification.OTHER]: [
    ShiftCancelClassification.OTHER,
    'Reflex - Other: No cancel fee',
  ],
  [ShiftCancelClassification.NONE_SPECIFIED]: [
    ShiftCancelClassification.NONE_SPECIFIED,
    'None Specified',
  ],
};

export enum ShiftAccessStatus {
  NONE = 'NONE',
  DATA = 'DATA_ERROR',
  MISSED_OWN = 'MISSED_OWN',
  MISSED_NOT_OWN = 'MISSED_OTHERS',
  FILLED_OWN = 'FILLED_OWN',
  FILLED_NOT_OWN = 'FILLED_OTHERS',
  OPEN = 'OPEN',
  CANCELLED = 'CANCELLED',
  COMPLETE = 'COMPLETE',
  COMPLETE_OWN = 'COMPLETE_OWN',
  COMPLETE_NOT_OWN = 'COMPLETE_OTHERS',
  INTEREST_SUCCESS = 'INTEREST_SUCCESS',
  INTEREST_SUCCESS_NO_CALL = 'INTEREST_SUCCESS_NO_CALL',
  ERROR_ACCEPT = 'ERROR_ACCEPT',
  ERROR_USER_FLAGGED = 'ERROR_USER_FLAGGED',
}

export enum ShiftPaymentType {
  TYPE_W2 = 'W2',
  TYPE_1099 = '1099',
  TYPE_INTERNAL = 'Internal',
}

// DEPRECATED, FUTURE: remove
export type apiRequiredBreak = {
  shift_minutes_to_require_break?: number;
  break_length?: number;
  paid?: boolean;
  blocks_payment?: boolean;
};

// DEPRECATED, FUTURE: remove
export type apiShiftDetail = {
  id?: number;
  uuid?: string;
  retailer?: Omit<apiRetailer, 'locations' | 'open_shifts'>;
  retailer_location?: number;
  status?: string;
  start?: string;
  end?: string;
  amount?: number;
  base_amount?: number;
  worker_roles?: Array<number>;
  description?: string;
  assigned_reflexer?: apiUserLight;
  assigned_reflexer_id?: number;
  info_integration?: string;
  contact_integration?: string;
  payment_type?:
    | ShiftPaymentType.TYPE_1099
    | ShiftPaymentType.TYPE_W2
    | ShiftPaymentType.TYPE_INTERNAL;
  retailer_location_type?: LocationType;
  retailer_location_name?: string;
  retailer_location_id?: number;
  retailer_location_uuid?: uuid;
  retailer_location_timezone?: string;
  retailer_location_address_text?: string;
  pending_review: number;
  pending_worker_review: number;
  workershift_events: apiWorkerShiftEvent[];
  required_break: apiRequiredBreak;
  shift_workers: Array<workershift>;
};

// DEPRECATED, FUTURE: remove
export type normalizedShift = {
  id: number;
  retailer: Omit<apiRetailer, 'locations' | 'open_shifts'>;
  locationId: number;
  status: string;
  startDate: Date;
  endDate: Date;
  payout: string;
  payoutBonus: string;
  payoutTotal: string;
  workerRoles: Array<number>;
  description: string;
  worker: normalizedUserLight;
  workerId: number;
  contactId: string;
  infoId: string;
  paymentType:
    | ShiftPaymentType.TYPE_1099
    | ShiftPaymentType.TYPE_W2
    | ShiftPaymentType.TYPE_INTERNAL;
  retailer_location_type: LocationType;
  retailer_location_name: string;
  retailer_location_id: number;
  retailer_location_uuid: uuid;
  retailer_location_timezone: string;
  retailer_location_address_text: string;
  pending_review: number;
  pending_worker_review: number;
  uuid: string;
  workershift_events: apiWorkerShiftEvent[];
  required_break: apiRequiredBreak;
  shift_workers: Array<workershift>;
};

// DEPRECATED, FUTURE: remove
export type apiShiftsPaginated = {
  count: number;
  next?: string;
  previous?: string;
  results: Array<apiShiftDetail>;
};

// DEPRECATED, FUTURE: remove
export type normalizedShiftsPaginated = {
  count: number;
  next?: string;
  previous?: string;
  results: Array<normalizedShift>;
};

export enum ShiftRequestType {
  DEFAULT = 'DEFAULT',
  FAVORITES = 'FAVORITES',
  REPEATS = 'REPEATS',
  LIST = 'LIST',
}

export enum ShiftRequestDetails {
  USERS = 'request_users',
}

export type shift = {
  acceptedCount?: number;
  acceptLimit?: number;
  actionableReasons?: string;
  activePlaybook?: activePlaybook;
  assignedWorkers?: Array<user>;
  benefitAccrualEvents?: Array<benefitAccrualEvent>;
  billingDiscount?: number;
  cancelClassification?: ShiftCancelClassification;
  cancelledWorkers?: Array<user>;
  cancelReason?: string;
  createdAt?: string; // Datetime
  communicationBatches?: communicationBatch[];
  description?: string;
  duration?: number;
  end?: string;
  flexibleEndTime?: boolean;
  flexibleStartTime?: boolean;
  id?: number;
  isCancelled?: boolean;
  isRetailerApproved?: boolean;
  logs?: Array<shiftAuditLog>;
  market?: string;
  notes?: noteType[];
  overbookedWorkers?: Array<user>;
  overbookLimit?: number;
  payments?: Array<payment>;
  paymentType?: ShiftPaymentType;
  pendingReviews?: Array<review>;
  requestDetails?: Partial<Record<ShiftRequestDetails, unknown>>;
  requestType?: ShiftRequestType;
  retailerLocation?: location;
  retailerNotes?: string;
  reviews?: Array<review>;
  shiftCriterias?: shiftCriteria[];
  shiftWorkers?: Array<workershift>;
  start?: string;
  status?: ShiftStatus;
  tags?: Array<tag>;
  updatedAt?: string; // Datetime
  uuid?: string;
  visibleWorkerCount?: number;
  workerRoles?: Array<role>;
  wsoCount?: number;
};

export enum ShiftActionErrorCode {
  FLAGGED = 'Flagged User',
  COMPLETED = 'Flex Completed',
  ACTIVE = 'Must be active',
  WEEKLY_LIMIT = 'Exceeds weekly limit',
  CANCELLED = 'Cancelled',
  CONFLICTING_SHIFT = 'Conflicting shift',
  ERROR = 'Error',
}
