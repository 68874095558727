export enum QueryParamDrawer {
  CAMPAIGNS_FILTERS = 'filter-campaigns',
  EDIT_TAGS = 'edit-tags',
  EDIT_RETAILER_USER = 'edit-retailer-user',
  LOCATION_EDIT = 'edit-location',
  LOCATIONS_FILTERS = 'filter-locations',
  MENU = 'menu',
  ONBOARDING = 'onboarding',
  PROGRESS_TRACKER = 'progress-tracker',
  REVIEWS_FILTERS = 'filter-reviews',
  SHIFT_EDIT = 'edit-shift',
  SHIFT_FILTERS = 'filter-shift',
  SHIFTS_FILTERS = 'filter-shifts',
  SHIFTS_SETTINGS = 'settings-shifts',
  TAGS_FILTERS = 'filter-tags',
  WORKER_EDIT = 'edit-worker',
  WORKERS_FILTERS = 'filter-workers',
}

export enum QueryParamModal {
  APPROVE_EMPLOYMENT_REQUIREMENT = 'approve-requirement',
  ATTRIBUTION_CODE = 'attribution-code',
  AVAILABILITY_DATES = 'availability-dates',
  AVAILABILITY_DATES_HELP = 'availability-dates-help',
  AVAILABILITY_DOW_HELP = 'availability-dow-help',
  AVAILABILITY_HELP = 'availability-help',
  BUDGETS_APPROVE = 'budgets-approve',
  BUDGETS_APPROVE_SHIFT = 'budgets-approve-shift',
  BUDGETS_EDIT = 'budgets-edit',
  CAMPAIGN_FORM = 'campaign-form',
  CAMPAIGN_RECORD_FORM = 'campaign-record-form',
  CANCEL_SHIFT = 'cancel-shift',
  CANCEL_WORKERSHIFT = 'cancel-worker-shift',
  CANCEL_ONBOARDING_CALL = 'cancel-onboarding-call',
  CODE_ROW_FORM = 'code-row-form',
  COHORT_IMPORTER = 'cohort-importer',
  COHORT_WIZARD = 'cohort-wizard',
  COMMUNICATION_BATCH = 'communication-batch',
  CREATE_CUSTOMER = 'create-customer',
  CREATE_NOTE = 'create-note',
  CREATE_RETAILER = 'create-retailer',
  CREATE_TERMS = 'create-terms',
  CREATE_USER = 'create-user',
  DELETE_NOTE = 'delete-note',
  DELETE_PAYMENT = 'delete-payment',
  EDIT_NOTE = 'edit-note',
  EDIT_PROFILE = 'edit-profile',
  EDIT_REVIEWS_STATUSES = 'edit-reviews-statuses',
  EDIT_USER_AVAILABILITY_DATE = 'edit-availability',
  EDIT_USERS_PROPERTIES = 'edit-users-properties',
  EDIT_WORKERSHIFT_EVENT = 'edit-workershift-event',
  ENTER_WORKFLOW = 'enter-workflow',
  FLAG_WORKER = 'flag-worker',
  LOCATION_FORM = 'location-form',
  LOCATIONS = 'locations',
  LOCATION_USER_CREATE = 'location-user-create',
  LOCATIONS_PLAYBOOK = 'locations-playbook',
  MARKETING_ACCESS = 'access',
  MARKETING_INFO = 'info',
  MARKETING_VIDEO = 'video',
  MESSAGE = 'message',
  OVERBOOK_SETTINGS = 'overbook-settings',
  OVERBOOK_TOOLS = 'overbook',
  OZETTA = 'ozetta',
  PLAYBOOK_WIZARD = 'playbook-wizard',
  PLAYBOOK_CHANGE = 'playbook-change',
  PLAYBOOK_CANCEL = 'playbook-cancel',
  PROFILE = 'profile',
  PROFILE_REVIEWS = 'reviews',
  RETAILER_ADD_PERMISSIONS = 'permissions-locations',
  RETAILER_BLOCK_WORKER = 'retailer-b',
  RETAILER_CANCEL_SHIFT = 'retailer-cancel-shift',
  RETAILER_EDIT_LOCATIONS = 'retailer-locations',
  RETAILER_EDIT_PERMISSIONS = 'permissions',
  RETAILER_EDIT_USER = 'retailer-user',
  RETAILER_EDIT_USER_INFO = 'retailer-user-edit',
  RETAILER_INVITE_USER = 'retailer-invite-user',
  RETAILER_SELECT_WORKERS = 'retailer-select-worker',
  RETAILER_SUPPORT = 'retailer-support',
  REVIEW_STATUS = 'review-status',
  REVIEW_FROM_WORKER = 'review-from-worker',
  SEND_DOCUMENTS = 'send-documents',
  SEND_PAPERWORK = 'send-paperwork',
  SETUP_WIZARD = 'setup-wizard',
  SHIFT_CONTACT = 'shift-contact',
  SHIFT_FORM = 'shift-form',
  SHIFT_INFO = 'shift-info',
  SHIFT_PAYMENT_INFO = 'shift-payment',
  SHIFT_SEARCH = 'shift-search',
  SHIFT_SPECIAL_REQUEST = 'shift-special-request',
  SHIFT_CRITERIA = 'shift-criteria',
  SUBMIT_REVIEW = 'submit-review',
  TAG_FORM = 'tag-form',
  UNFLAG_WORKER = 'unflag-worker',
  UPSERT_PAYMENT = 'upsert-payment',
  WORKER_ACTION = 'worker-action',
  WORKER_IMAGE = 'worker-image',
  WORKERS_SELECT_ALL = 'workers-select-all',
  WIZARD_BRAND = 'wizard-brand',
  WIZARD_CUSTOMER = 'wizard-customer',
  WIZARD_LOCATION = 'wizard-location',
  WIZARD_ORGANIZATION = 'wizard-organization',
  WIZARD_USER = 'wizard-user',
  WORKER_BENEFIT_SPEND = 'worker-benefit-spend',
}

export enum QueryParamWorkerTab {
  DETAILS = 'details',
  ONBOARDING = 'onboarding',
  EXPERIENCE = 'experience',
  SHIFTS = 'shifts',
  REVIEWS = 'reviews',
  AVAILABILITY = 'availability',
  RELATIONS = 'relations',
  MESSAGES = 'messages',
  NOTES = 'notes',
  FLAGS = 'flags',
  DANGER = 'danger',
}
