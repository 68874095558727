import { _gql, applyContext, fetchObject, Queries, redirectIfMaintenanceMode } from 'helpers';
import { GQL, paging } from 'types';

export interface fetchMarketsProps extends paging {
  isActive?: boolean;
}

export const fetchMarkets = (data: fetchMarketsProps) =>
  fetchObject({
    route: applyContext(GQL, 'MARKETS_LIST_QUERY'),
    query: Queries.MARKET_LIST_QUERY,
    data,
    returnKey: ['markets', 'markets_paging'],
  });

export const fetchMarketsLegacy = async () => {
  const response = await _gql(
    applyContext(GQL, 'MARKETS_LIST_QUERY'),
    Queries.MARKET_LIST_QUERY,
    {},
  )
  .then(redirectIfMaintenanceMode)
  .then(res =>
    res.json().catch(err => {
      throw new Error(err);
    }),
  );
  if (response.errors) {
    throw new Error(response.errors[0]);
  }
  return response.data;
};
