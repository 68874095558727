import { focusColor } from './colors.config';

export const ButtonVariants = {
  primary: {
    color: 'main.white',
    backgroundColor: 'green.500',
    _hover: {
      backgroundColor: 'green.400',
      _disabled: {
        backgroundColor: 'green.500',
      },
    },
    _active: {
      backgroundColor: 'green.600',
    },
  },
  greenish: {
    fontWeight: 'medium',
    backgroundColor: 'green.50',
    color: 'green.500',
    borderColor: 'green.500',
    borderWidth: 1.5,
    borderStyle: 'solid',
    _hover: {
      bg: 'green.100',
      _disabled: {
        bg: 'green.100',
      },
    },
  },
  lightGreen: {
    fontWeight: 'medium',
    backgroundColor: 'green.50',
    color: 'green.500',
  },
  light: {
    fontWeight: 'medium',
    backgroundColor: 'main.sky',
    color: 'text.secondary',
    borderColor: 'gray.100',
    borderWidth: 1.5,
    borderStyle: 'solid',
    _hover: {
      bg: 'gray.50',
      _disabled: {
        bg: 'gray.50',
      },
    },
  },
  dark: {
    fontWeight: 'medium',
    backgroundColor: 'worker.primary',
    color: 'oz.white',
    borderStyle: 'solid',
    _hover: {
      bg: 'stone.800',
      _disabled: {
        bg: 'stone.800',
      },
    },
  },
  transparent: {
    colorScheme: 'grey',
    color: 'text.hint',
    backgroundColor: 'transparent',
    borderColor: 'transparent',
    fontWeight: 'medium',
    _hover: {
      backgroundColor: 'blackAlpha.50',
      _disabled: {
        bg: 'blackAlpha.50',
      },
    },
    _active: {
      backgroundColor: 'blackAlpha.100',
    },
  },
  danger: {
    color: 'main.white',
    backgroundColor: 'red.500',
    _hover: {
      backgroundColor: 'red.600',
      _disabled: {
        bg: 'red.600',
      },
    },
    _active: {
      backgroundColor: 'red.600',
    },
  },
  redish: {
    fontWeight: 'medium',
    backgroundColor: 'pink.50',
    color: 'red.500',
    borderColor: 'red.500',
    borderWidth: 1.5,
    borderStyle: 'solid',
    _hover: {
      bg: 'red.100',
      _disabled: {
        bg: 'red.100',
      },
    },
  },
};

const DeprecatedVariants = {
  pink: {
    color: 'main.white',
    backgroundColor: 'pink.500',
    _hover: {
      backgroundColor: 'pink.400',
      _disabled: {
        bg: 'pink.400',
      },
    },
    _active: {
      backgroundColor: 'pink.600',
    },
  },
  navy: {
    color: 'main.white',
    backgroundColor: 'navy.500',
    _hover: {
      backgroundColor: 'navy.400',
      _disabled: {
        bg: 'navy.400',
      },
    },
    _active: {
      backgroundColor: 'navy.600',
    },
  },
  concrete: {
    color: 'main.white',
    backgroundColor: 'navy.400',
    _hover: {
      backgroundColor: 'navy.500',
      _disabled: {
        bg: 'navy.500',
      },
    },
    _active: {
      backgroundColor: 'navy.600',
    },
  },
  link: {
    alignSelf: 'flex-start',
    mt: 1,
    mb: 2,
    textDecoration: 'underline',
    color: 'main.blue',
    sx: {
      textDecorationColor: 'blue.100',
      textDecorationThickness: '1.5px',
      textUnderlineOffset: '4px',
    },
    _hover: {
      color: 'blue.400',
      bg: 'blue.100',
      _disabled: { color: 'blue.400', bg: 'blue.100' },
    },
    _active: { color: 'blue.400', bg: 'blue.100' },
    transition: 'background 0.2s',
  },
  subtle: {
    color: 'text.disabled',
    backgroundColor: 'bg.light',
    _hover: {
      backgroundColor: 'blackAlpha.50',
      _disabled: {
        bg: 'blackAlpha.50',
      },
    },
    _active: {
      backgroundColor: 'blackAlpha.100',
    },
  },
  orangish: {
    fontWeight: 'medium',
    backgroundColor: 'orange.50',
    color: 'orange.600',
    borderColor: 'orange.500',
    borderWidth: 1.5,
    borderStyle: 'solid',
    _hover: {
      bg: 'orange.100',
      _disabled: {
        bg: 'orange.100',
      },
    },
  },
};

export const Button = {
  baseStyle: {
    fontWeight: 'bold',
    borderRadius: 'lg',
    _focus: {
      boxShadow: `0 0 0 2px ${focusColor}`,
    },
  },
  sizes: {
    xl: {
      fontSize: 'xl',
      h: 14,
      minW: 12,
      px: 6,
      py: 8,
    },
  },
  defaultProps: {
    variant: 'primary',
    colorScheme: 'green',
  },
  variants: { ...ButtonVariants, ...DeprecatedVariants },
};
